body {
  font-family: "Rubik", sans-serif;
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("/fonts/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("/fonts/Rubik-Regular.ttf") format("truetype");
}

.list {
  list-style-type: none;

  li {
    display: flex;
    padding: 5px 0;
    font-size: 13px;

    &::before {
      content: ">";
      color: #ff9761;
      margin-right: 10px;
      font-weight: 500;
    }
  }
}

// This is added to to match the styles when re-order list items are dragged to maintain the styles
.reorder-list-item {
  display: flex;
  flex-direction: row;
  cursor: pointer;

  .option-index {
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 0;
    font-size: 26px;
    font-weight: 400;
  }

  .option {
    flex-grow: 1;
    border: 1px solid #c2c2c2;
    background-color: #f6f6f6;
    padding: 19px 24px 19px 33px;
    margin: 10px 0;
    border-radius: 10px;
    min-height: 88px;
    display: flex;
    align-items: center;
    line-height: 1.6;
  }
}

.v-align-center {
  display: flex;
  align-items: center;
  height: 100%;
}

.video-react {
  overflow: hidden;

  * {
    overflow: hidden;
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

.video-react .video-react-video {
  position: relative !important;
  border-radius: 4px;
}

.video-react:hover .video-react-big-play-button,
.video-react .video-react-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;

  &::before {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

.video-react-poster {
  background-color: #ffffff !important;
}

a {
  text-decoration: none !important;
}
