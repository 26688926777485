.glide__arrow{
  border: none;
  padding: 9px 2px;
  border-radius: 0px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .glide__arrow--left{
    left: 0px;
   }
   
   .glide__arrow--right{
     right: 0px;
   }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
  
}