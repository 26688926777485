* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  overflow: hidden;
}
.screen-cntr {
  height: 100%;
  overflow: auto;
}
/* width */
::-webkit-scrollbar {
  /* width: 8px; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #c9c9c9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}
