.support-component-wrapper {
  max-width: 750px;
  margin: 0 auto;
  margin-top: 50px;

  .table {
    border-top: 2px solid #000000;
    margin-top: 40px;

    .MuiTableCell-head {
      font-size: 1.1rem;
    }

    .MuiTableCell-body {
      font-size: 1rem;
    }
  }
}
