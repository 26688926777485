.auth-form-cntr {
    display: flex;
    align-items: center;

    .form {
        width: 100%;

        .rf-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 15px 0;
            font-family: 'Rubik', sans-serif;

            span,
            a {
                font-size: 13px;
                color: #717171;
                text-decoration: none;
            }
        }

        .register-btn {
            color: #A51C01;
        }
    }
}

.errorMsg {
    height: 1.375em;
    color: #fb5d22;
}

.flexBox {
    display: flex;
}

.MuiFormControl-root {
    margin-top: 2rem;
}

.MuiFormControl-root .loginBtn {
    background: #ffbc36;
}

.MuiFormControl-root .loginBtn:hover {
    background: #ffbc36;
}

.text-right {
    text-align: right;
}

.my-3 {
    margin: 1rem 0;
}

.d-block {
    display: block;
}

.lfFont {
    width: 260px;
    height: 120px;
    font-family: Heebo;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.8;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.lfFontB {
    font-weight: 500;
}

.leftBoxLogin {
    width: 380px;
    padding: 20px;
    background: #fafafa;
    float: left;
    padding-left: 77px;
    padding-top: 170px;
}

.rightBoxLogin {
    width: 986px;
    padding: 20px;
    float: right;
    text-align: center;
    margin: 0px auto;
    padding-top: 170px;
}

.rightBoxLogin form {
    width: 386px;
    float: none;
    margin: 0px auto;
}

.flexBox.fbox {
    display: flex;
    width: 78%;
    height: 100%;
    margin: 0px auto;
}

.container {
    position: relative;
}