.rform-wrapper {
    flex-direction: column;

    .submit-btn {
        margin: 15px 0;
    }
}

.errorMsg {
    height: 1.375em;
}

.login-link {
    color: #A51C01 !important;
    font-weight: 500;
    font-size: 13px;
}

.text-center {
    text-align: center;
}
